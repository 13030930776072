import moment from 'dayjs';
import actionTypes from 'constants/actionTypes';

const calculateStartTime = () => {
  const startTime = moment();
  const remainder =
    startTime.minute() >= 30
      ? 120 - (startTime.minute() % 30)
      : 120 - startTime.minute();
  return startTime.add(remainder, 'minutes');
};

const calculateEndTime = () => moment(calculateStartTime()).add(3, 'h');

const initialBookingState = () => ({
  startTime: calculateStartTime(),
  endTime: calculateEndTime(),
  user: {},
  address: undefined,
  familySettings: {},
  shortlists: [],
  is_flexible: false,
  coupon: {},
  gift_certificate: {},
  step: 1,
  startedAt: null,
  subscriptionPlan: null,
  multi: false,
  cg_can_bring_kids: null,
  serviceId: null,
  serviceOption: null,
  recurring_request: false // added this to try and default the booking form to false for recurring bookings unless user checks it
});

const initialState = {
  booking: {
    ...initialBookingState()
  },
  lastBooking: {},
  loading: false,
  errors: {},
  successfullyBooked: false,
  couponLoading: false,
  couponError: null,
  successfullyLoadedBookingsSummary: false,
  apiCalled: false,
  noShowReported: false
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BOOKING_START:
      return {
        ...state,
        booking: {
          ...initialBookingState(),
          startTime: calculateStartTime(),
          endTime: calculateEndTime(),
          shortlists: state.booking.shortlists,
          startedAt: moment().format('YYYY-MM-DD HH:mm'),
          step: 1,
          serviceId: action.serviceId,
          isChildCare: action.isChildCare
        },
        loading: false,
        successfullyBooked: false,
        couponLoading: false
      };
    case actionTypes.BOOKING_RESET:
      return {
        ...state,
        booking: { ...initialBookingState() },
        errors: {},
        successfullyBooked: false,
        couponLoading: false
      };
    case actionTypes.BOOKING_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        successfullyBooked: false,
        cancelFailed: false
      };
    case actionTypes.BOOKING_SENT:
      return {
        ...state,
        loading: false,
        errors: {},
        successfullyBooked: true,
        lastBooking: action.booking,
        booking: { ...initialBookingState() }
      };
    case actionTypes.BOOKING_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyBooked: false
      };

    // modify BOOKING_UPDATE to default recurring_request to false
    case actionTypes.BOOKING_UPDATE:
      return {
        ...state,
        booking: {
          ...state.booking,
          ...action.booking,
          recurring_request: action.booking.recurring_request ?? false
        },
        apiCalled: true
      };
    // modify BOOKING_UPDATE_ATTRIBUTE to keep recurring checkbox false unless its being updated
    case actionTypes.BOOKING_UPDATE_ATTRIBUTE:
      return {
        ...state,
        booking: {
          ...state.booking,
          [action.attributeName]: action.attributeValue,
          recurring_request:
            action.attributeName === 'recurring_request'
              ? action.attributeValue
              : state.booking.recurring_request
        },
        apiCalled: true
      };
    case actionTypes.BOOKING_COUPON_LOADING:
      return {
        ...state,
        couponError: null,
        couponLoading: action.loading
      };
    case actionTypes.BOOKING_COUPON:
      return {
        ...state,
        couponLoading: false,
        booking: {
          ...state.booking,
          coupon: { ...action.coupon }
        }
      };
    case actionTypes.BOOKING_GIFT_CERTIFICATE:
      return {
        ...state,
        couponLoading: false,
        booking: {
          ...state.booking,
          gift_certificate: { ...action.gift_certificate }
        }
      };
    case actionTypes.BOOKING_COUPON_ERROR:
      return {
        ...state,
        couponLoading: false,
        couponError: action.error
      };
    case actionTypes.BOOKING_CHANGE_STEP:
      return {
        ...state,
        booking: {
          ...state.booking,
          step: action.step
        }
      };
    case actionTypes.BOOKINGS_ERRORS:
      return {
        ...state,
        errors: action.errors,
        loading: false,
        apiCalled: true
      };
    case actionTypes.BOOKING_NO_SHOW_FLAG:
      return {
        ...state,
        noShowReported: action.noShowReported
      };
    case actionTypes.BOOKING_RESET_ERRORS:
      return {
        ...state,
        errors: {},
        cancelFailed: false
      };
    default:
      return state;
  }
};

export default bookingReducer;
